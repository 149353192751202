


























































































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Ref, Vue } from 'vue-property-decorator'
import { IdoApplyViewModel } from '../viewmodel/ido-apply-viewmodel'

@Observer
@Component({
  components: {},
})
export default class TicketWinning extends Vue {
  @Inject() vm!: IdoApplyViewModel
  fakeTickets = [0, 1, 2, 3, 4, 5, 0, 1, 2, 3, 4, 0, 1, 2, 3, 4, 0, 1, 2, 3, 4]
}
