var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-6 border-radius-10 text-center flex-column-align-center algo-grey darken-1 winning-ticket-padding"},[_c('div',{staticClass:"notice-winning-text"},[_vm._v(" "+_vm._s(_vm.vm.hasWinningTickets ? 'Congratulations, You Won!' : 'Unfortunately, you lost this time :(')+" ")]),_c('v-img',{staticClass:"my-6",attrs:{"src":require(("@/assets/images/winning" + (_vm.vm.winningTickets.length > 0 ? '' : '-none') + "-cup-image.svg")),"max-height":_vm.$vuetify.breakpoint.xl ? 157 : 106,"contain":""}}),(_vm.vm.hasWinningTickets)?_c('div',{staticClass:"flex-column-align-center fill-width"},[_c('div',{staticClass:"ticket-frame-width border-radius-10 common-border-card fill-width d-flex flex-column",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"d-flex align-center justify-center fill-width ticket-header-height",class:{
          'ticket-header-border': _vm.vm.isShowWinningTickets,
          'py-4': _vm.vm.goldenTicketNumber > 0 && _vm.$vuetify.breakpoint.smAndDown,
        }},[(_vm.vm.goldenTicketNumber > 0)?_c('div',{staticClass:"border-radius-5 fill-width whitelist-bold-label",class:{
            'd-flex flex-column align-center': _vm.$vuetify.breakpoint.smAndDown,
            'd-flex justify-center align-center': !_vm.$vuetify.breakpoint.smAndDown,
          }},[_c('div',{staticClass:"ticket-text"},[_vm._v(" Winning ticket(s) : "),(_vm.vm.winningTicketNotGolden > 0)?_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.vm.winningTicketNotGolden))]):_vm._e()]),(_vm.vm.winningTicketNotGolden > 0)?_c('v-img',{staticClass:"mx-2 plus-icon",attrs:{"src":require("@/assets/icons/plus-dark-icon.svg"),"contain":""}}):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between align-center px-2 golden-tickets",class:{
              'mt-2': _vm.vm.winningTicketNotGolden === 0 && _vm.$vuetify.breakpoint.smOnly,
              'ml-2': _vm.vm.winningTicketNotGolden === 0 && !_vm.$vuetify.breakpoint.smOnly,
            }},[_c('v-img',{attrs:{"src":require("@/assets/icons/golden-star-icon.svg"),"max-width":"13","contain":""}}),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm.vm.depositedGoldenTicket)+" Golden Ticket"+_vm._s(_vm.vm.depositedGoldenTicket > 1 ? 's' : ''))]),_c('v-img',{attrs:{"src":require("@/assets/icons/golden-star-icon.svg"),"max-width":"13","contain":""}})],1)],1):_c('div',{staticClass:"ticket-text",style:(_vm.$vuetify.breakpoint.smAndDown ? 'height: 48px;' : '')},[_vm._v(" Winning ticket(s) : "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.vm.winningTickets.length))])])]),_c('v-btn',{staticClass:"enable-scroll-ticket",attrs:{"icon":""},on:{"click":_vm.vm.changeShowWinningTickets}},[_c('v-img',{attrs:{"src":require(("@/assets/icons/arrow-" + (_vm.vm.isShowWinningTickets ? 'up' : 'down') + "-circle-icon.svg")),"max-width":_vm.$vuetify.breakpoint.xl ? 32 : 28,"contain":""}})],1),(_vm.vm.isShowWinningTickets && _vm.vm.winningTickets.length > 0)?_c('div',{staticClass:"d-flex flex-wrap align-start justify-start align-self-center black--text pb-6 pt-2 winning-ticket-frame"},_vm._l((_vm.vm.winningTickets),function(item,index){return _c('div',{key:index,staticClass:"mx-1 mt-4 d-flex align-center justify-center",class:{
            'golden-ticket-box': index < _vm.vm.goldenTicketNumber,
            'winning-ticket-box': index >= _vm.vm.goldenTicketNumber,
          }},[(index >= _vm.vm.goldenTicketNumber)?_c('span',[_vm._v(_vm._s(item))]):_vm._e()])}),0):_vm._e()],1),_c('div',{staticClass:"mt-6",class:{
        'd-flex align-center': !_vm.$vuetify.breakpoint.smAndDown,
        'd-flex flex-column align-center': _vm.$vuetify.breakpoint.smAndDown,
      }},[_c('div',{staticClass:"claim-open-time"},[_vm._v("Claim opens in :")]),_c('div',{staticClass:"step-time-box ml-2"},[_vm._v(_vm._s(_vm._f("datetimeStandard")(_vm.vm.claimStart)))])]),_c('div',{staticClass:"fill-width algo-grey mt-16 mb-9",staticStyle:{"height":"1px"}})]):_vm._e(),(_vm.vm.hasRefundTickets || _vm.vm.hasWinningTickets)?_c('div',{staticClass:"flex-column-align-center fill-width"},[_c('div',{staticClass:"ticket-frame-width border-radius-10 common-border-card fill-width d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center justify-center fill-width ticket-header-height",class:{
          'ticket-header-border': _vm.vm.hasRefundTickets && _vm.vm.isShowNoneWinningTickets,
        },staticStyle:{"position":"relative"},style:(_vm.$vuetify.breakpoint.smAndDown ? 'height: 48px;' : '')},[_c('div',{staticClass:"ticket-text"},[_vm._v(" None winning ticket(s) : "),_c('span',{staticClass:"algo-grey-lighten1--text"},[_vm._v(_vm._s(_vm.vm.noneWinningTickets.length))])]),_c('v-btn',{staticClass:"enable-scroll-ticket",attrs:{"icon":""},on:{"click":_vm.vm.changeShowNoneWinningTickets}},[_c('v-img',{attrs:{"src":require(("@/assets/icons/arrow-" + (_vm.vm.isShowNoneWinningTickets ? 'up' : 'down') + "-circle-icon.svg")),"max-width":_vm.$vuetify.breakpoint.xl ? 32 : 28,"contain":""}})],1)],1),(_vm.vm.isShowNoneWinningTickets && _vm.vm.hasRefundTickets)?_c('div',{staticClass:"d-flex flex-wrap align-start justify-start black--text align-self-center pb-6 pt-2 winning-ticket-frame"},_vm._l((_vm.vm.noneWinningTickets),function(item,index){return _c('div',{key:index,staticClass:"mx-1 mt-4 d-flex align-center justify-center none-winning-ticket-box"},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()]),(_vm.vm.hasRefundTickets)?_c('v-btn',{staticClass:"d-flex flex-start text-none mt-6 algo-btn btnA",class:{
        'button-50 border-radius-5': !_vm.$vuetify.breakpoint.xl,
        'button-60 ': _vm.$vuetify.breakpoint.xl,
        'fill-width ': _vm.$vuetify.breakpoint.smAndDown,
        'algo-btn btnA--disabled': !_vm.vm.canRefund,
        'algo-btn btnA': _vm.vm.canRefund,
      },style:(!_vm.$vuetify.breakpoint.smAndDown ? 'min-width:265px' : ''),attrs:{"loading":_vm.vm.refundLoading || _vm.vm.fetchingData},on:{"click":function($event){return _vm.vm.refund()}}},[_vm._v("Refund ")]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }